<template>
    <div>
        <div class="sticky-top">
            <step-progress :names="['R. NALOG', 'POSAO', 'POTVRDA']" :currentStep.sync="currentStep" :disabledSteps="disabledSteps" />
        </div>

        <div class="main-panel">
            <template v-if="currentStep === 0">
                <work-order-picker-panel :allWorkOrders="allWorkOrders" :allWorkers="allWorkers" :selected-work-order="selectedWorkOrder" :selected-workers.sync="selectedWorkers"
                    @change:work-order="wo => updateWorkOrder(wo)" @change:worker="wId => onWorkerChanged(wId)"
                    :confirmation="false" />
            </template>
            <template v-else-if="currentStep === 1">
                <task-selector-panel :workOrder="selectedWorkOrder" :workLocations="allWorkLocations" :workPositions="allWorkPositions" :selectedTasks.sync="selectedTasks"  />
            </template>
            <template v-if="currentStep === 2">
                <job-assignment-summary-panel :allWorkOrders="allWorkOrders" :allWorkers="allWorkers" :selected-work-order="selectedWorkOrder" :selected-workers.sync="selectedWorkers"
                    @change:work-order="wo => updateWorkOrder(wo)" @change:worker="wId => onWorkerChanged(wId)"
                    @submitAssignment="submitAssignment" />
            </template>
        </div>
    </div>
</template>

<script>
import StepProgress from '@/components/stepper/StepProgress.vue'
import WorkOrderPickerPanel from './panels/WorkOrderPickerPanel.vue';
import TaskSelectorPanel from './panels/TaskSelectorPanel.vue';
import {mapState} from 'vuex'

import EmployeesService from '@/service/EmployeesService.js';
import WorkOrderService from '@/service/WorkOrderService.js';
import UtilService from '@/service/UtilService.js';
import JobAssignmentService from '@/service/JobAssignmentService.js';
import JobAssignmentSummaryPanel from './panels/JobAssignmentSummaryPanel.vue';

export default {
    components: { StepProgress, WorkOrderPickerPanel, TaskSelectorPanel, JobAssignmentSummaryPanel },

    computed: {
        ...mapState([
            'allWorkOrders',
            'userAssignment',
        ]),

        queryWorkOrderId() {
            return parseInt(this.$route.query.workOrderId);
        },

        queryWorkPositionId() {
            return parseInt(this.$route.query.workPositionId);
        },

        queryWorkLocationId() {
            return parseInt(this.$route.query.workLocationId);
        },

        queryWorkerId() {
            return parseInt(this.$route.query.workerId);
        },

        minTime() {
            if (this.selectedDate == this.formatDatePattern(this.getMoment())) return this.formatDatePattern(this.getMoment(), "HH:mm");
            return "";
        },

        disabledSteps() {
            let disabledSteps = []

            if (this.selectedWorkOrder == null) disabledSteps.push({index: 1, comment: "Morate izabrati radni nalog", successive: true})
            if (!this.selectedTasks || this.selectedTasks.length == 0) disabledSteps.push({index: 2, comment: "Morate izabrati posao", successive: true})

            return disabledSteps
        }
    },
        

    data() {
        return {
            currentStep: 0,
            selectedWorkOrderId: null,



            selectedDate: this.formatDatePattern(UtilService.getMoment()),
            selectedTime: this.formatDatePattern(UtilService.addDate(UtilService.getMoment(), '5', 'minutes'), "HH:mm"),
            selectedWorkOrder: null,
            selectedWorkers: [],
            selectedWorkPositions: null,
            selectedWorkLocations: null,
            selectedTasks: [],
            assignmentComment: "",

            allWorkers: [],
            allWorkersAttendance: null,
            allWorkPositions: [],
            allWorkLocations: [],
        }
    },

    methods: {
        formatDatePattern: UtilService.formatDatePattern,
        getMoment: UtilService.getMoment,
        addDate: UtilService.addDate,

        openAddWorkerModal() {
            this.$refs.modalWorkerSelect.showModal();
        },

        openWorkPositionsModal() {
            this.$refs.ModalWorkPositionsAssignmentModal.showModal();
        },

        openWorkLocationsModal() {
            this.$refs.ModalWorkLocationsAssignmentModal.showModal();
        },

        getNameInitials(employee) {
            return employee.first_name.slice(0, 1) + employee.last_name.slice(0, 1)
        },

        selectWorker(worker) {
            this.selectedWorkers.push(worker)
        },

        async submitAssignment() {

            let payload = {
                    data: {
                        workOrderId: this.selectedWorkOrder.id,
                        workersId: this.selectedWorkers.map(x=> x.id),
                        tasksId: this.selectedTasks.map(x=>x.id),
                        comment: this.assignmentComment
                    }
                }
                
                try {
                    let response = await JobAssignmentService.postWorkAssignment(payload);
                    alert("Upuceno")
                    console.log(response.data)
                    this.resetForm()

                } catch (error) {
                    console.log(error)
                }
        },

        resetForm() {
            this.selectedWorkers = []
            this.selectedWorkPositions = null
            this.selectedWorkLocations = null
            this.assignmentComment = ""
            this.refreshWorkPositions().then(() => {
                this.currentStep = 1;
            });


        },

        onDateTimeChanged() {
            this.loadWorkOrderWorkers()
        },

        onLoadWorkPositions() {
        },

        updateStep(idx) {
            this.currentStep = idx;
        },

        updateWorkOrder(workOrder) {
            this.selectedWorkOrder = workOrder
            this.onWorkOrderChanged()
        },

        onWorkOrderChanged() {
            this.loadWorkOrderWorkers()
            this.refreshWorkPositions();
        },

        onWorkerChanged(workers) {
            this.selectedWorkers = workers
        },


        async refreshWorkPositions() {
            await this.loadWorkPositions(this.selectedWorkOrder.id)
            await this.loadWorkLocations(this.selectedWorkOrder.id)
            this.calculateWorkLocationPositionStatus(this.allWorkPositions, this.allWorkLocations)
        },

        calculateWorkLocationPositionStatus(workPositions, workLocations) {
            UtilService.recursiveDoFunction(workLocations, (workLocation)=> {
                workLocation?.tasks_obj.forEach(task => {
                    task.work_location_obj = UtilService.recursiveFindElement(workLocations, (workLocation) => {return workLocation.id == task.work_location_id})
                    task.work_position_obj = UtilService.recursiveFindElement(workPositions, (workPosition) => {return workPosition.id == task.work_position_id})
                });
            })

            UtilService.recursiveDoFunction(workPositions, (workPosition)=> {
                workPosition?.tasks_obj.forEach(task => {
                    task.work_location_obj = UtilService.recursiveFindElement(workLocations, (workLocation) => {return workLocation.id == task.work_location_id})
                    task.work_position_obj = UtilService.recursiveFindElement(workPositions, (workPosition) => {return workPosition.id == task.work_position_id})
                });
            })
        },

        async loadAssignedWorkers() {
            try{
                let result = await EmployeesService.getAvailableWorkersInWorkOrder({
                    'work_order_id': this.selectedWorkOrder.id, 
                    'datetime': this.selectedDate + 'T' + this.selectedTime
                });
                this.allWorkers = result.data;
            } catch (error) {
                this.$toasted.show("Neuspešno učitavanje radnika")   
            }
        },

        async loadWorkOrderWorkersAttendance() {
            try{
                let result = await WorkOrderService.getWorkOrderAttendance({
                    'work_order_id': this.selectedWorkOrder.id, 
                    'date': this.selectedDate
                    });

                this.allWorkersAttendance = result.data;
                
            } catch (error) {
                this.$toasted.show("Neuspešno učitavanje radnika")   
            }
        },

        async loadWorkOrderWorkers() {
            try{
                this.loadAssignedWorkers()

                await this.loadWorkOrderWorkersAttendance();

                let self = this;
                this.allWorkers.forEach(worker => {
                    let wa = this.allWorkersAttendance.find(x => worker.id == x.id)
                    worker.absence = wa.absence;
                    if (!wa.work_attendance) return

                    let startTime = UtilService.convertMoment(wa.work_attendance.time_start, "HH:mm:ss")
                    let endTime = UtilService.convertMoment(wa.work_attendance.time_end, "HH:mm:ss")
                    let current = UtilService.convertMoment(self.selectedTime, "HH:mm")
                    if ( current.isBetween(startTime, endTime))
                        worker.available = true;
                    else
                        worker.available = false;

                });


            } catch (error) {
                console.log(error);
                this.$toasted.show("Neuspešno učitavanje radnika, refreshjte stranicu")   
            }
        },


        async loadWorkPositions(workOrderId) {
            try {
                let workPositionsResponse = await WorkOrderService.getWorkOrderPositionsDetailed({url: {workOrderId: workOrderId}})
                let workPositions = workPositionsResponse.data
                UtilService.workPositionTasksDetailsCalc(workPositions)

                this.allWorkPositions = workPositions
            } catch {
                alert("Neuspesno ucitavanje pozicija")
            }
        },

        async loadWorkLocations(workOrderId) {
            try {
                let payload = {
                    url: {workOrderId: workOrderId}
                }

                let workLocationsResponse = await WorkOrderService.getWorkOrderLocationsDetailed(payload)
                let workLocations = workLocationsResponse.data

                this.allWorkLocations = workLocations
                return workLocations
            } catch {
                alert("Neuspesno ucitavanje pozicija")
            }
        },


        paramsAutoSelectWorkOrder() {
            if (this.queryWorkOrderId) {
                let workOrder = this.allWorkOrders.find(x => x.id == this.queryWorkOrderId);
                if (workOrder){
                    this.selectedWorkOrder = workOrder;
                    this.onWorkOrderChanged();
                }
                else{
                    this.$toasted.show("Radni nalog nije dostupan")   
                }

            } else {
                if (this.userAssignment?.pwo?.work_order_obj?.id) {
                    let workOrder = this.allWorkOrders.find(x => x.id == this.userAssignment.pwo.work_order_obj.id);
                    this.selectedWorkOrder = workOrder || null;
                    this.onWorkOrderChanged();
                }
            }
        },


        async getUserWorkAttendance() {
            await this.$store.dispatch('loadUserWorkAttendance');
        },

        async loadData() {
            Promise.all([
                this.getUserWorkAttendance(),
                this.$store.dispatch('loadAllWorkOrders')]
            ).then(() => {
                this.paramsAutoSelectWorkOrder();
            });
        }
    },

    created() {
        this.loadData()
    }
}
</script>

<style scoped>
.main-panel {
    /* border: 1px solid red; */
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: auto;
}

.stick-to-bottom {
    border: 1px solid rebeccapurple;
    position: absolute;
    left: 0px;
    bottom: 20px;
}
</style>
