<template>
    <div class="input-group flex-nowrap pb-1">
        <div class="input-group-text">
            <input class="form-check-input mt-0" type="checkbox" :value="work_position.id" @click="checkboxChanged($event)">
        </div>

        <div :class="'accordion-item-' + work_position.id" class="light-border w-100">
            <h2 class="accordion-header" :id="'heading-' + work_position.id">
                <button class="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-' + work_position.id">
                    {{ work_position.name }}
                </button>
                <div class="row">
                    <div class="col-12">
                        <div class="container">
                            <div class="row justify-content-around">
                                <div class="col-2 p-0" v-for="(status, index) in statusesSum" :key="index">
                                    <template v-if="!!status.count">
                                        <div class="card status-card mb-0"
                                            v-bind:style="{ backgroundColor: statusColor[status.status] }">
                                            <div class=" card-body p-1">
                                                <h6 class="card-text status-card-text mb-0">
                                                    <span
                                                        class="d-inline-block d-md-none w-100 text-center">
                                                        {{ [status.count] }}
                                                    </span>
                                                    <span class="d-none d-md-inline">
                                                        {{ [status.count] }}</span>
                                                    <span class="d-none d-md-inline">
                                                        {{ statusTranslations[status.status] }}</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </h2>
            <div :id="'collapse-' + work_position.id" class="accordion-collapse collapse">
                <div class="accordion-body">
                    <template v-if="work_position.children && work_position.children.length > 0">
                        <WorkPositionAccordion v-for="item in work_position.children" :key="item.id" :work_position="item"
                            @change:checkbox="$emit('change:checkbox', $event)" />
                    </template>

                    <template v-else>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="location, index in sortedLocations" :key="index">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        {{ location.work_location_obj.name }}
                                    </div>
                                    <div class="light-border p-1"
                                        v-bind:style="{ backgroundColor: statusColor[location.task_status] }">
                                        {{ statusTranslations[location.task_status] }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WorkPositionAccordion',

    props: {
        work_position: {
            type: Object,
            required: true
        }
    },
    emits: ['change:checkbox'],
    data() {
        return {
            statusColor: {
                CREATED: '#9E9E9E',
                READY: '#F1C40F',
                NOT_READY: '#9E9E9E',
                IN_PROGRESS: '#3498DB',
                DONE: '#1ABC9C',
            },
            statusTranslations: {
                CREATED: "NIJE SPREMNO",
                READY: "SPREMNO",
                NOT_READY: "NIJE SPREMNO",
                IN_PROGRESS: "U TOKU",
                DONE: "ZAVRŠENO"
            }
        }
    },

    methods: {
        checkboxChanged($event) {
            let id = parseInt($event.target.value);
            let value = $event.target.checked;
            this.$emit('change:checkbox', { id, value })
        }
    },

    computed: {

        statusesSum() {
            let results = []
            const statuses = ['CREATED', 'READY', 'IN_PROGRESS', 'DONE'];

            for (const status of statuses) {
                let result = {
                    status: status,
                    count: this.work_position.tasks_status_sum[status] || 0,
                }
                if (status === "CREATED") result.count += this.work_position.tasks_status_sum["NOT_READY"]
                results.push(result);
            }
            return results
        },

        sortedLocations() {
            const statusOrder = { CREATED: 0, READY: 1, NOT_READY: 2, IN_PROGRESS: 3, DONE: 4 };
            const groupedLocations = this.work_position.tasks_obj.reduce((acc, location) => {
                const status = location.task_status;
                if (!(status in acc)) {
                    acc[status] = [];
                }
                acc[status].push(location);
                return acc;
            }, {});
            return Object.entries(groupedLocations)
                .sort(([aStatus], [bStatus]) => statusOrder[aStatus] - statusOrder[bStatus])
                .reduce((acc, [, locations]) => acc.concat(locations), []);
        },
    }
}


</script>

<style scoped>
.status-card {
    overflow: hidden;
    white-space: nowrap;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.status-card-text {
    font-size: 12px;
}

.light-border {
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>
