<template>
    <div>
        <modal-work-history ref="modalWorkHistory" :task="selectedTask" @onUpdate="updateTask" :workOrderId="workOrder.id" />


        <modal-work-positions-assignment ref="ModalWorkPositionsAssignment" :workPositions="workPositions"
            @change:checkbox="$event => positionCheckboxToggled($event)" @onConfirm="selectWorkPosition" />
        <modal-work-locations-assignment ref="ModalWorkLocationsAssignment" :workLocations="workLocations"
            @change:checkbox="$event => locationCheckboxToggled($event)" @onConfirm="selectWorkLocations" />
        <div class="container-fluid">
            <div class="row mb-3">
                <div class="col-6">
                    <label for="positionPicker" class="form-label">Izaberi pozicije</label>
                    <button type="button" class="btn btn-outline-secondary btn-sm w-100" id="positionPicker"
                        @click="openWorkPositionsModal">Pozicije</button>
                </div>
                <div class="col-6">
                    <label for="locationPicker" class="form-label">Izaberi lokacije</label>
                    <button type="button" class="btn btn-outline-secondary btn-sm w-100" id="locationPicker"
                        @click="openWorkLocationsModal">Lokacije</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <!-- <p>{{ selectedLocations }}</p> -->
                    <!-- <p>{{ selectedPositions }}</p> -->
                    <template v-if="selectedTasksData && selectedTasksData.length > 0">
                        <h4>Izabrali ste {{ selectedTasksData.length }} {{ getWordPosao(selectedTasksData.length) }}</h4>
                    </template>
                    <template v-else>
                        <h4>Izaberite poslove:</h4>
                    </template>
                    <position-location-grid 
                        ref="positionLocationGrid" 
                        :tasksGridData="tasksGridData" 
                        :taskStatusSum="['READY']" 
                        @openHistoryModal="openHistoryModal"
                        :editMode="true"
                        :expandedView="true"
                        :selectedTasks.sync="selectedTasksData"
                        @update:selectedTasks="updatedSelectedTask" 
                        />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalWorkPositionsAssignment from '@/components/job-assignment/modals/ModalWorkPositionsAssignment.vue';
import ModalWorkLocationsAssignment from '@/components/job-assignment/modals/ModalWorkLocationsAssignment.vue';
import PositionLocationGrid from '@/components/work-orders/PositionLocationGrid.vue';
import WorkOrderService from '@/service/WorkOrderService';
import ModalWorkHistory from '@/components/modals/ModalWorkHistory.vue';
import UtilService from '@/service/UtilService.js';

import { Freeze } from "@syncfusion/ej2-vue-grids";


export default {
    components: { ModalWorkPositionsAssignment, ModalWorkLocationsAssignment, PositionLocationGrid, ModalWorkHistory },
    provide: {
        grid: [Freeze]
    },

    props: {
        workLocations: Array,
        workPositions: Array,
        workOrder: Object,
        selectedTasks: Array,
    },

    watch: {
        selectedTasks: {
            handler(val) {
                this.selectedTasksData = val
            }
        }
    },

    data() {
        return {
            tasks: [],
            selectedLocations: [],
            selectedPositions: [],
            selectedTasksData: this.selectedTasks,
            selectedTask: null,

            tasksGridData: {
                columns: [],
                rows: [],
            },
        }
    },

    methods: {
        openWorkPositionsModal() {
            this.$refs.ModalWorkPositionsAssignment.showModal();
        },

        openWorkLocationsModal() {
            this.$refs.ModalWorkLocationsAssignment.showModal();
        },

        selectWorkPosition($event) {
            console.log($event)
        },

        selectWorkLocations($event) {
            console.log($event)
        },
        
        onSelectTask(tasks) {
            console.log(tasks);
        },

        getWordPosao(count) {
            return UtilService.pluralizeSR(count, ["posao", "posla", "poslova"])
        },

        openHistoryModal(task) {
            this.selectedTask = task
            this.$refs.modalWorkHistory.showModal(task);
        },

        locationCheckboxToggled($event) {
            console.log($event);

            const id = $event.id;
            const index = this.selectedLocations.findIndex(e => e === id);
            if ($event.value || index === -1) {
                this.selectedLocations.push(id);
            } else {
                this.selectedLocations.splice(index, 1);
            }
            this.getWorkOrderTasksGrid()
        },

        updateTask(task) {
            if (this.selectedTask?.id === task?.id){
                UtilService.objectDeepAssign(this.selectedTask, task)
            }
        },

        positionCheckboxToggled($event) {
            console.log($event);
            const id = $event.id;
            const index = this.selectedPositions.findIndex(e => e === id);
            if ($event.value || index === -1) {
                this.selectedPositions.push(id);
            } else {
                this.selectedPositions.splice(index, 1);
            }
            this.getWorkOrderTasksGrid()
        },

        async getWorkOrderTasksGrid() {
            let payload = {
                url: {workOrderId: this.workOrder.id},
                params: {
                    workPositionsId: this.selectedPositions.join(",") || null,
                    workLocationsId: this.selectedLocations.join(",") || null,
                }
            }
            try {
                let response = await WorkOrderService.getWorkOrderTasksGrid(payload)
                let tasksGridData = response.data
                this.tasksGridData = tasksGridData


            } catch (error) {
                console.log(error);
            }

        },

        updatedSelectedTask() {
            this.$emit("update:selectedTasks", this.selectedTasksData);
        },

        loadData() {
            this.getWorkOrderTasksGrid()
        }

    },

    created() {
        this.loadData()
    }
}

</script>

<style scoped></style>