<template>
    <div class="accordion" aria-multiselectable="true">
        <div class="input-group flex-nowrap pb-1" v-for="child in workLocations" :key="child.id">
            <div class="input-group-text px-1" v-if="child.tasks_obj && child.tasks_obj.length > 0">
                <input class="form-check-input mt-0" type="checkbox" :value="child.id" @click="checkboxChanged($event)">
            </div>
            <div class="accordion-item w-100">
                <button class="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-' + child.id">
                    <span class="flex-grow-1 d-flex justify-content-between">
                        <span>{{ child.name }}</span>
                        <span>
                            <!-- <span class="badge rounded-pill bg-secondary">0</span>
                            <span class="badge rounded-pill bg-warning">2</span>
                            <span class="badge rounded-pill bg-secondary">4</span>
                            <span class="badge rounded-pill bg-secondary">0</span> -->
                        </span>
                    </span>
                </button>
                <div class="accordion-collapse" aria-labelledby=" accordion-header">
                    <div :id="'collapse-' + child.id" class="accordion-collapse collapse">
                        <div class="accordion-body pt-1 pb-2 px-2">
                            <LocationAccordion v-if="child.children && child.children.length > 0" :workLocations="child.children || []" :depthLevel="depthLevel + 1"
                                @change:checkbox="$emit('change:checkbox', $event)" />
                            <ul class="list-group" v-else>
                                <li class="list-group-item"
                                    v-for="task in getSortedTasks(child.tasks_obj)"
                                    :key="task.id">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            {{ task.work_position_obj.name }}
                                        </div>
                                        <div class="light-border p-1"
                                            v-bind:style="{ backgroundColor: statusColor[task.task_status] }">
                                            {{ statusTranslations[task.task_status] }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LocationAccordion',
    props: {
        workLocations: {
            type: Array,
            required: true
        },

        depthLevel: {
            type: Number,
            default: 0
        }
    },
    emits: ['change:checkbox'],
    data() {
        return {
            statusColor: {
                CREATED: '#9E9E9E',
                READY: '#F1C40F',
                NOT_READY: '#A3DAFF',
                IN_PROGRESS: '#3498DB',
                DONE: '#1ABC9C',
            },
            statusTranslations: {
                CREATED: "NIJE SPREMNO",
                READY: "SPREMNO",
                NOT_READY: "NIJE SPREMNO",
                IN_PROGRESS: "U TOKU",
                DONE: "ZAVRŠENO"
            }
        }
    },

    methods: {
        getSortedTasks(tasks) {
            return tasks.slice().sort()
        },

        statusComparator(a, b) {
            if (a === "CREATED") return -1;
            if (a === "DONE") return 1;

            if (a === "READY")
                if (b === "CREATED") return 1
                else if (b === "READY") return 0
                else return -1

            if (a === "NOT_READY")
                if (b === "READY" || b === "CREATED") return 1
                else if (b === "NOT_READY") return 0
                else return -1

            if (a === "IN_PROGRESS")
                if (b === "NOT_READY" || b === "READY" || b === "CREATED") return 1
                else if (b === "IN_PROGRESS") return 0
                else return -1

            return 0;
        },

        checkboxChanged($event) {
            const id = parseInt($event.target.value);
            let value = $event.target.checked;
            this.$emit('change:checkbox', { id, value })
        }
    }

}
</script>

<style scoped>
.accordion-button:focus {
    box-shadow: none !important;
}
</style>