<template>
    <div class="job-assignment">
        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container">
        <job-assignment-form v-bind="$props" />
        </div>

    </div></template>

<script>
import Navbar from '@/components/Navbar.vue'
import JobAssignmentForm from '@/components/job-assignment/JobAssignmentForm.vue'

export default {
    components: {
        Navbar,
        JobAssignmentForm,
    },
    props: ['workOrderId', 'workLocationId', 'workPositionId', 'worker'],
}
</script>

<style>

</style>
