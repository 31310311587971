<template>
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <label for="woPicker" class="form-label">Izaberite radni nalog</label>
                <select class="form-control" id="woPicker" v-model="currentWorkOrder" :disabled="confirmation"
                    placeholder="Radni nalog" @change="onWorkOrderUpdate">
                    <option disabled :value=null>Izaberite radni nalog</option>
                    <option v-for="wo in allActiveWorkOrders" :value=wo :key=wo.id>
                        {{ wo.name }}
                    </option>
                </select>
            </div>
        </div>
        <template v-if="!confirmation">
            <div class="row">
                <div class="col-xs-12">
                    <!-- <info-map></info-map> -->
                </div>
            </div>
        </template>
        <template v-if="!!confirmation">
            <div class="row">
                <div class="col-xs-12">
                    POSLOVI
                </div>
            </div>
        </template>
        <div class="row mt-5">
            <div class="col-xs-12">
                <label for="workerPicker" class="form-label">Radnici na radnom nalogu</label>


                <div class="list-group" id="workerPicker">
                    <label v-for="w in allWorkersData" class="list-group-item" :key="w.id">
                        <!-- <input class="form-check-input me-1" type="checkbox" :checked="isChecked(w.id)" @change="() => onWorkerChanged(w.id)"> -->
                        <span class="worker-name">
                            {{ `${w.first_name} ${w.last_name}` }}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UtilService from '@/service/UtilService.js';

export default {
    components: {},
    props: { allWorkOrders: Array, allWorkers:Array, selectedWorkOrder: Object, selectedWorkers: Array, confirmation: Boolean },
    emits: ['change:work-order', 'change:worker'],

    computed: {
        todayDate() {
            return UtilService.formatDatePattern(UtilService.getMoment())
        },

        allActiveWorkOrders() {
            return this.allWorkOrders.filter(x => !x.real_end_date || x.real_end_date > this.todayDate)
        },
    },
    
    data() {
        return {
            currentWorkOrder: this.selectedWorkOrder,
            selectedWorkersData: [],

            // TODO - available workers
            allWorkersData: this.allWorkers
        }
    },

    watch: { 
        selectedWorkOrder: function(newVal) {
            this.currentWorkOrder = newVal
        },

        allWorkers: function(newVal) {
            this.allWorkersData = newVal
        }
    },

    methods: {
        onWorkOrderUpdate() {
            this.$emit('change:work-order', this.currentWorkOrder);
        },

        isChecked(id) {
            return this.$props.selectedWorkers.includes(id);
        },

        onWorkerChanged() {
            
            this.$emit('change:worker', this.selectedWorkersData);
        }
    },

}
</script>

<style scoped>
.worker-name {
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 200;
    text-align: center;
    white-space: nowrap;
}
</style>