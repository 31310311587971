<template>
    <div class="step-container">
        <ul class="progressbar">
            <li :class="{disabled:currentStepData === 0}" ref="backButton" @click="backClicked"></li>
            <li ref="steps" @click="() => updateStep(index)" :class="getStepClass(index)" v-for="name, index in names" :key=index>
                <div>
                    {{ name }}
                </div>
            </li>
            <li :class="{disabled:currentStepData === names.length-1}" ref="nextButton" @click="nextClicked"></li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        disabledSteps: Array[Object],
        names: Array[String],
        currentStep: {
            type: Number,
            default: 0,
        }
    },
    emits: ['update:step', 'click:next', 'click:back'],

    watch: {
        currentStep(newVal) {
            this.currentStepData = newVal
        }
    },

    data() {
        return {
            currentStepData: this.currentStep
        }
    },

    methods: {

        getStepClass(i) {
            if (i <= this.currentStepData) return "active"
            else return ""
        },

        updateStep(index) {
            let disabledStep = this.disabledSteps.find(x => (x.successive && index >= x.index) || x.index === index)
            if (disabledStep) {
                if (disabledStep.comment) alert(disabledStep.comment)
                return
            }

            this.currentStepData = index;
            this.$emit('update:currentStep', this.currentStepData);
        },


        backClicked() {
            this.updateStep(Math.max(0, this.currentStepData - 1));
            this.$emit('click:back', this.currentStepData);
        },

        nextClicked() {
            this.updateStep(Math.min(this.names.length - 1, this.currentStepData + 1));
            this.$emit('click:next', this.currentStepData);
        },


    },

}
</script>
  
<style scoped>
* {
    --button-primary: #007BFF;
    --primary: #3aac5d;
    --secondary: #bebebe;
    --gray: #979797;
}

.step-container {
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    padding-top: 10px;
    background-color: white;
}

.progressbar {
    counter-set: step -1;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0px;
    bottom: 0px;
    padding: 0px;
    margin: 0px;
}

.progressbar li {
    float: left;
    width: 100%;
    position: relative;
    list-style-type: none;
    text-align: center;
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    border: 2px solid var(--secondary);
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 15%;
    line-height: 27px;
    background: white;
    color: var(--secondary);
    text-align: center;
    font-weight: bold;
}

.progressbar li:hover:before {
    cursor: pointer;
}

.progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: var(--gray);
    top: 15px;
    left: -50%;
    z-index: -1;
}

/* active connecting line */
.progressbar li.active:before {
    border-color: var(--primary);
    background: var(--primary);
    color: white
}

.progressbar li.active:after {
    background: var(--primary);
}

.progressbar li:first-child {
    width: max-content;
}

.progressbar li:last-child {
    width: max-content;
}

.progressbar li:first-child:before {
    border-color: var(--button-primary);
    background: var(--button-primary);
    color: white;
    width: 40px;
    margin-left: 0px;
    content: "<";
}

.progressbar li:last-child:before {
    border-color: var(--button-primary);
    background: var(--button-primary);
    color: white;
    width: 40px;
    margin-right: 0px;
    content: ">";
}

.progressbar li:nth-child(-n + 2):after {
    content: none;
}

.progressbar li:last-child:after {
    content: none;
}
</style>