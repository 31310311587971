<template>
    <div class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Izaberite pozicije</h5>
                    <button type="button" class="btn-close" @click="cancelModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="accordion">
                        <work-position-accordion v-for="item in workPositions" :key="item.id" :work_position="item"
                            @change:checkbox="$emit('change:checkbox', $event)"></work-position-accordion>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row w-100">
                        <!-- <div class="col-6">
                            <button type="button" class="btn btn-secondary w-100" @click="cancelModal()">Nazad</button>
                        </div> -->
                        <div class="col-12">
                            <button type="button" class="btn btn-primary w-100" @click="confirmModal()">Sačuvaj</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
import WorkPositionAccordion from './WorkPositionAccordion.vue'

export default {

    components: { WorkPositionAccordion },

    props: {
        workPositions: {
            type: Array,
            required: true
        }
    },
    emits: ['change:checkbox'],
    data() {
        return {
            modal: null,
        }
    },

    methods: {

        confirmModal() {
            this.hideModal()
            this.$emit('onConfirm', this.workLocations);
        },

        cancelModal(data) {
            this.hideModal()
            this.$emit('onCancel', data);
        },

        onModalShown() {

        },

        showModal() {
            this.modal.show();
        },
        hideModal() {
            this.modal.hide();
        },

        setUpListener() {
            let myModalEl = this.$el
            this.modal = new Modal(myModalEl, { keyboard: false });

            myModalEl.addEventListener('shown.bs.modal', () => {
                this.onModalShown();
            })
        },
    },

    mounted() {
        this.setUpListener();
    },

}
</script>

<style scoped>
.modal-content {
    height: calc(100vh - 3.5rem);
}

.modal-body {
    overflow: auto;
}
</style>
